import React, { Component } from 'react';
import CampaignMapCoordinate from './CampaignMapCoordinate';
import CampaignMapControls from './CampaignMapControls';
import CampaignMapMarkerPopUp from './CampaignMapMarkerPopUp';
import Loader from '../../components/Loader';


class CampaignMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      campaignMap: this.props.campaignMap,
      controlsMarkers: this.props.controlsMarkers,
      selectedColor: null,
      boardSelectedMarker: null,
      controlsSelectedMarker: null,
      controlsSelectedIndicator: false,
      indicatorIteration: 0,
      rightClickedMarker: null,
      isMenuPoppedUp: false,
      isNewMapRequestPoppedUp: false,
      isNewMarkerRequestPoppedUp: false,
      isMultiMarkerMenuPoppedUp: false,
      nameContent: null,
      typedPublicNotesValue: null,
      typedPrivateNotesValue: null,
      selectedMenuHex: null,
      isDeleteToggled: false,
      isDeleteControlToggled: false,
      isHideToggled: false,
      isColorOptionsMenuPoppedUp: false,
      hoveredMarker: null,
      dragFilledCoordinates: [],
      typedNewHeight: "",
      typedNewWidth: "",
      awareUsers: [],
      markerType: null,
      loading: false
    }
    this.selectHexColor = this.selectHexColor.bind(this);
    this.selectMapMarker = this.selectMapMarker.bind(this);
    this.selectMapIndicator = this.selectMapIndicator.bind(this);
    this.requestNewMap = this.requestNewMap.bind(this);
    this.submitNewMap = this.submitNewMap.bind(this);
    this.createNewMarkerClick = this.createNewMarkerClick.bind(this);
    // this.createNewMarker = this.createNewMarker.bind(this);
    this.deleteControlsMarker = this.deleteControlsMarker.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handlePopUpSave = this.handlePopUpSave.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleLeaveHover = this.handleLeaveHover.bind(this);
    this.handleStopFill = this.handleStopFill.bind(this);
    this.handleCoordinateClick = this.handleCoordinateClick.bind(this);
    this.handleCoordinateRightClick = this.handleCoordinateRightClick.bind(this);
    this.handleControlMarkerRightClick = this.handleControlMarkerRightClick.bind(this);
    this.handleExpandedIconClick = this.handleExpandedIconClick.bind(this);
    this.handleExpandedIconRightClick = this.handleExpandedIconRightClick.bind(this);
    this.handleExpandedIconHover = this.handleExpandedIconHover.bind(this);
    this.handleExpandedIconLeaveHover = this.handleExpandedIconLeaveHover.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypedPublicNotesValueChange = this.handleTypedPublicNotesValueChange.bind(this);
    this.handleTypedPrivateNotesValueChange = this.handleTypedPrivateNotesValueChange.bind(this);
    // this.handleMarkerColorClick = this.handleMarkerColorClick.bind(this);
    // this.handleMarkerColorPopUpClick = this.handleMarkerColorPopUpClick.bind(this);
    this.handleTypedNewHeightChange = this.handleTypedNewHeightChange.bind(this);
    this.handleTypedNewWidthChange = this.handleTypedNewWidthChange.bind(this);
    this.handleUserInclusionClick = this.handleUserInclusionClick.bind(this);
    this.handleMarkerTypeClick = this.handleMarkerTypeClick.bind(this);
    this.toggleDeleteMarker = this.toggleDeleteMarker.bind(this);
    this.toggleDeleteControlMarker = this.toggleDeleteControlMarker.bind(this);
    this.toggleHideMarker = this.toggleHideMarker.bind(this);
    // this.checkIfPermitted = this.checkIfPermitted.bind(this);
    this.setStateToMarkerSettings = this.setStateToMarkerSettings.bind(this);
  }

  componentDidMount() {
    this.props.socket.on("update map", (serverData) => {
      let data = JSON.parse(serverData);
      this.setState((prevState) => {
        return {
          campaignMap: data.campaign_map,
          controlsMarkers: data.markers,
          loading: false,
          typedNewHeight: "",
          typedNewWidth: ""};
      });
    })

    // "map placement marker" ws event returns to this websocket
    this.props.socket.on("map placement marker", (serverData) => {
      let data = JSON.parse(serverData);
      if (data.type === 'new_marker') {
        this.setState((prevState) => {
          if (data.affected_coordinates && data.affected_coordinates.length > 0) {
            const campaignMap = {...prevState.campaignMap};
            campaignMap.coordinates.forEach((coordinate, index) => {
              data.affected_coordinates.forEach((affected_coordinate) => {
                if (coordinate.id === affected_coordinate.id) {
                  campaignMap.coordinates[index] = affected_coordinate;
                }
              });
            });
            return { campaignMap, controlsMarkers: data.controls_markers };
          } else {
            return { controlsMarkers: data.controls_markers };
          }
        });
      } else if (data.type === 'place_marker') {
        this.setState((prevState) => {
          const campaignMap = {...prevState.campaignMap};
          campaignMap.coordinates.forEach((coordinate, index) => {
            data.affected_coordinates.forEach((affected_coordinate) => {
              if (coordinate.id === affected_coordinate.id) {
                campaignMap.coordinates[index] = affected_coordinate;
              }
            });
          });
          return { campaignMap, controlsMarkers: data.controls_markers, controlsSelectedMarker: null };
        });
      } else if (data.type === 'move_marker') {
        this.setState((prevState) => {
          const campaignMap = {...prevState.campaignMap};
          const indicatorIteration = prevState.indicatorIteration + 1
          campaignMap.coordinates.forEach((coordinate, index) => {
            data.affected_coordinates.forEach((affected_coordinate) => {
              if (coordinate.id === affected_coordinate.id) {
                campaignMap.coordinates[index] = affected_coordinate;
              }
            });
            if (this.state.boardSelectedMarker && coordinate.id === this.state.boardSelectedMarker.map_coordinate_id) {
              coordinate.indicatorIteration = indicatorIteration;
            }
          });
          return { campaignMap, boardSelectedMarker: null, indicatorIteration };
        });
      }
    })

    // "update marker", "delete coord marker", and "delete map marker"
    // ws events all return to this websocket
    this.props.socket.on("marker updates", (serverData) => {
      let data = JSON.parse(serverData);
      this.setState((prevState) => {
        if (data.affected_coordinates && data.affected_coordinates.length > 0) {
          const campaignMap = {...prevState.campaignMap};
          campaignMap.coordinates.forEach((coordinate, index) => {
            data.affected_coordinates.forEach((affected_coordinate) => {
              if (coordinate.id === affected_coordinate.id) {
                campaignMap.coordinates[index] = affected_coordinate;
              }
            });
          });
          return { campaignMap, controlsMarkers: data.controls_markers };
        } else {
          return { controlsMarkers: data.controls_markers };
        }
      });
    })


    this.props.socket.on("update coordinate", (serverData) => {
      let data = JSON.parse(serverData);
      this.setState((prevState) => {
        const campaignMap = {...prevState.campaignMap};
        const dragFilledCoordinates = [...prevState.dragFilledCoordinates];
        campaignMap.coordinates.forEach((coordinate, index) => {
          data.affected_coordinates.forEach((affected_coordinate) => {
            if (coordinate.id === affected_coordinate.id) {
              campaignMap.coordinates[index].color_hex_code = affected_coordinate.color_hex_code;
            }
          });
        });
        dragFilledCoordinates.push(data.coord_id)
        return { campaignMap, dragFilledCoordinates: dragFilledCoordinates, hoveredMarker: null };
      });
    })

    this.props.socket.on("show map indicator", (serverData) => {
      this.setState((prevState) => {
        const campaignMap = {...prevState.campaignMap};
        const indicatorIteration = prevState.indicatorIteration + 1
        campaignMap.coordinates.forEach((coordinate, index) => {
          if (coordinate.id === serverData) {
            coordinate.indicatorIteration = indicatorIteration;
            coordinate.indicator = true;
          }
          else if (coordinate.indicator) {
            coordinate.indicator = false;
          }
        });
        return { campaignMap, indicatorIteration };
      });
    })
  }

  // checkIfPermitted() {
  //   return this.props.currentUser && this.props.currentUser.role ? true : false;
  // }

  handleTypedPublicNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
      this.setState((prevState) => {
        return {
          typedPublicNotesValue: val
        };
      });
    }
  }

  handleTypedPrivateNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 300) {
      this.setState((prevState) => {
        return {
          typedPrivateNotesValue: val
        };
      });
    }
  }

  handleNameChange(e) {
    e.preventDefault();
    this.setState({ nameContent: e.target.value });
  }

  selectHexColor(e, hex) {
    if(this.props.currentUser.role) {
      if (this.state.selectedColor && hex.code === this.state.selectedColor.code) {
        this.setState({
          selectedColor: null
        });
      } else {
        this.setState({
          selectedColor: hex
        });
      }
    }
  }

  selectMapMarker(e, marker) {
    e.preventDefault();
    if (
      marker.map_coordinate_id === null &&
      (
        this.props.currentUser.role ||
        (marker.character && this.props.currentUser.id === marker.character.creator_id)
      )
    ) {
      if (this.state.controlsSelectedMarker && marker.id === this.state.controlsSelectedMarker.id) {
        this.setState({
          controlsSelectedMarker: null
        });
      } else {
        this.setState({
          controlsSelectedMarker: marker,
          controlsSelectedIndicator: false
        });
      }
    }
  }

  selectMapIndicator(e) {
    e.preventDefault();
    if (this.state.controlsSelectedIndicator) {
      this.setState({
        controlsSelectedIndicator: false
      });
    } else {
      this.setState({
        controlsSelectedMarker: null,
        controlsSelectedIndicator: true
      });
    }
  }

  requestNewMap(e) {
    e.preventDefault();
    this.closePopUp();
    this.setState({
      isNewMapRequestPoppedUp: true
    });
  }

  createNewMarkerClick(e) {
    e.preventDefault();
    this.closePopUp();
    this.setState({
      isNewMarkerRequestPoppedUp: true
    });
  }

  // createNewMarker(e, marker=null) {
  //   e.preventDefault();
  //   let formPayload = new FormData();
  //   formPayload.append('name', this.state.nameContent);
  //   formPayload.append('public_notes', this.state.typedPublicNotesValue);
  //   formPayload.append('private_notes', this.state.typedPrivateNotesValue);
  //   formPayload.append('selected_hex', this.state.selectedMenuHex);
  //   let url = `/api/v1/campaign_map/${this.state.campaignMap.id}/outright_marker`;
  //   // const response = await fetch(url, { method: 'POST', body: formPayload, credentials: 'same-origin'});
  //
  //   fetch(url, {
  //     method: 'POST',
  //     body: formPayload,
  //     credentials: 'same-origin'
  //   })
  //   .then(response =>
  //     response.json().then(body => ({
  //       body: body,
  //       status: response.status
  //     })
  //   ).then(res => {
  //     if (res.status === 200) {
  //       this.setState((prevState) => {
  //         return {
  //           controlsMarkers: res.body.controls_markers
  //         };
  //       });
  //     } else {
  //       console.log('Problem Creating Marker')
  //     }
  //   }));
  //   this.closePopUp();
  //   this.props.getMapDetails();
  //
  //   // const json = await response.json();
  //   // this.setState((prevState) => {
  //   //   return {
  //   //     controlsMarkers: json.controls_markers
  //   //   };
  //   // });
  //   // this.closePopUp();
  // }

  async deleteControlsMarker(e, marker) {
    e.preventDefault();
    if(this.props.currentUser.role) {
      this.props.socket.emit("delete map marker", {
        mapId: this.state.campaignMap.id,
        markerId: marker.id,
      });
      this.closePopUp();
    }
  }

  async submitNewMap(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        isNewMapRequestPoppedUp: false,
        loading: true
      };
    });
    let campaignId = this.props.campaign ? this.props.campaign.id : null;
    if (campaignId === null) {
      this.setState((prevState) => {
        return {
          loading: false
        };
      });
      return;
    }
    this.props.socket.emit("update map", {
      campaignId: campaignId,
      rows: this.state.typedNewHeight,
      columns: this.state.typedNewWidth,
    });
    this.closePopUp();
  }

  closePopUp(e = null) {
    if (e) {
      e.preventDefault();
    }
    this.setState((prevState) => {
      return {
        isMenuPoppedUp: false,
        isNewMapRequestPoppedUp: false,
        isNewMarkerRequestPoppedUp: false,
        nameContent: null,
        typedPublicNotesValue: null,
        typedPrivateNotesValue: null,
        selectedMenuHex: null,
        isDeleteToggled: false,
        isDeleteControlToggled: false,
        isColorOptionsMenuPoppedUp: false,
        rightClickedMarker: null,
        hoveredMarker: null,
        dragFilledCoordinates: [],
        awareUsers: [],
        markerType: null,
        boardSelectedMarker: null,
        controlsSelectedMarker: null,
      }
    });
  }

  async handlePopUpSave(e = null, marker) {
    if (e) {
      e.preventDefault();
    }
    if(this.props.currentUser.role) {
      // Updating marker details or removing from map
      let requestType = this.state.isDeleteToggled || this.state.isDeleteControlToggled ? 'delete coord marker' : 'update marker';
      let markerId, coordinateId, type;
      if (marker) {
        markerId = marker.id;
        if (marker.map_coordinate_id !== null) {
          coordinateId = marker.map_coordinate_id;
        } else {
          coordinateId = null;
        }
      } else {
        requestType = 'map placement marker';
        type = 'new_marker';
        markerId = null;
        coordinateId = null;
      }
      this.props.socket.emit(requestType, {
        mapId: this.state.campaignMap.id,
        type: type,
        markerId: markerId,
        coordinateId: coordinateId,
        origCoordinateId: null,
        destCoordinateId: null,
        name: this.state.nameContent,
        isDeleteToggled: this.state.isDeleteToggled,
        isDeleteControlToggled: this.state.isDeleteControlToggled,
        publicNotes: this.state.typedPublicNotesValue,
        privateNotes: this.state.typedPrivateNotesValue,
        isHiding: this.state.isHideToggled,
        markerType: this.state.markerType,
        awareUsers: JSON.stringify(this.state.awareUsers)
      });
      this.closePopUp();
    }
  }

  async handleCoordinateClick(e, coord) { // handles when coordinates are clicked
    e.preventDefault();
    e = e || window.event;

    // consider also using ("which" in e) (values are 1 & 3 instead) for browsers: Gecko (Firefox), WebKit (Safari/Chrome), IE & Opera
    // if ("button" in e && coordinate) {
    //  if (e.button === 0) {
    if (coord) {
      let coordMarker;
      let coordMarkersLength = 0;
      // DO PERMISSIONS HERE - it is possible that what may be multi-markers
      // on a coord for DM will be just one marker for PC
      if (coord.markers) {
        coordMarkersLength = coord.markers.length;
        coord.markers.forEach((marker) => {
          if (['PC','NPC','Enemy'].includes(marker.marker_type.name)) {
            coordMarker = marker;
          }
        });
      }
      // ======= before anything - close any open menus if a non-menu element was clicked on (a coordinate) =======
      if (this.state.isMenuPoppedUp || this.state.isMultiMarkerMenuPoppedUp) {
        this.setState((prevState) => {
          return {
            isMenuPoppedUp: false,
            rightClickedMarker: null,
            isMultiMarkerMenuPoppedUp: false
          };
        });
      }
      // ======= create an indicator on map coordinate first - if indicator is selected =======
      if (this.state.controlsSelectedIndicator) {
        this.props.socket.emit("map ping", coord.id);
      }
      // ======= move board-selected marker to new space =======
      // MAKE SURE THAT THIS CHECK IS ALSO IN THE API (PC NPC or ENEMY)
      else if (
        this.state.boardSelectedMarker &&
        this.state.boardSelectedMarker.map_coordinate_id !== coord.id &&
        (
          this.props.currentUser.role ||
          (
            !this.props.currentUser.role &&
            this.state.boardSelectedMarker.marker_type.name === 'PC' &&
            this.state.boardSelectedMarker.character &&
            this.state.boardSelectedMarker.character.creator_id === this.props.currentUser.id
          )
        ) &&
        (
          !coordMarker ||
          (
            coordMarker &&
            !['PC','NPC','Enemy'].includes(this.state.boardSelectedMarker.marker_type.name)
          )
        )
      ) {
        this.props.socket.emit("map placement marker", {
          mapId: this.state.campaignMap.id,
          type: "move_marker",
          markerId: this.state.boardSelectedMarker.id,
          markerType: this.state.markerType,
          origCoordinateId: this.state.boardSelectedMarker.map_coordinate_id,
          destCoordinateId: coord.id,
          name: null,
          publicNotes: null,
          privateNotes: null,
          isHiding: null,
          awareUsers: null,
        });
      }
      // ======= place controls-selected marker on unoccupied space =======
      else if (
        this.state.controlsSelectedMarker &&
        (
          !coordMarker ||
          (
            coordMarker &&
            !['PC','NPC','Enemy'].includes(this.state.controlsSelectedMarker.marker_type.name)
          )
        )
      ) {
        if (!this.state.controlsSelectedMarker.coordinate) {
          if (
            this.props.currentUser.role ||
            (this.state.controlsSelectedMarker.character && this.props.currentUser.id === this.state.controlsSelectedMarker.character.creator_id)
          ) {
            this.props.socket.emit("map placement marker", {
              mapId: this.state.campaignMap.id,
              type: "place_marker",
              markerId: this.state.controlsSelectedMarker.id,
              markerType: this.state.markerType,
              origCoordinateId: null,
              destCoordinateId: coord.id,
              name: null,
              publicNotes: null,
              privateNotes: null,
              isHiding: null,
              awareUsers: null,
            });
          }
        }
      }
      // ======= when there are multiple coord marker, show available markers =======
      else if (coordMarkersLength > 1) {
        // close pop up if it is open
        if (this.state.isMultiMarkerMenuPoppedUp) {
          this.setState((prevState) => {
            return {
              isMultiMarkerMenuPoppedUp: false
            };
          });
        }
        // pop up a mini menu of icons
        else {
          this.setState((prevState) => {
            return {
              isMultiMarkerMenuPoppedUp: true
            };
          });
        }
      }
      // ======= when there is a marker in a coord, select a marker when nothing is selected =======
      else if (coordMarkersLength === 1) {
        // unselect currently-selected marker
        if (
          this.state.boardSelectedMarker &&
          this.state.boardSelectedMarker.id === coord.markers[0].id &&
          (
            this.props.currentUser.role ||
            (
              this.state.boardSelectedMarker.character &&
              this.state.boardSelectedMarker.character.creator_id === this.props.currentUser.id
            )
          )
        ) {
          this.setState((prevState) => {
            return {
              boardSelectedMarker: null,
              controlsSelectedMarker: null
            };
          });
        }
        // select a newly clicked marker
        else {
          if (
            this.props.currentUser.role ||
            (
              coord.markers[0] &&
              coord.markers[0].character &&
              coord.markers[0].character.creator_id === this.props.currentUser.id
            )
          ) {
            this.setState((prevState) => {
              return {
                boardSelectedMarker: coord.markers[0],
                controlsSelectedMarker: null
              };
            });
          }
        }
      }
    }
  }

  async handleStopFill(e) {
    e.preventDefault();
    if (this.state.dragFilledCoordinates) {
      this.setState({ dragFilledCoordinates: [] });
    }
  }

  async handleCoordinateRightClick(e, coord) { // handles when coordinates are clicked
    e.preventDefault();
    console.log('Right clicked')
    // ======= right click can fill in environment with different types of boundaries if nothing fills that spot =======
    if (
      this.props.currentUser.role &&
      (
        !coord.markers ||
        (
          coord.markers &&
          coord.markers.length === 0
        )
      )
      && this.state.selectedColor
    ) {
      this.props.socket.emit("update coordinate", {
        mapId: this.state.campaignMap.id,
        colorHex: this.state.selectedColor.code,
        coordinateId: coord.id,
      });
    }
    // ======= right click can provide a drop-down menu for a marker (and close any other previously open menus) =======
    else {
      this.closePopUp();
      if (coord.markers) {
        if (coord.markers.length === 1) {
          this.setStateToMarkerSettings(coord.markers[0]);
          this.setState((prevState) => {
            return {
              rightClickedMarker: coord.markers[0],
              isMultiMarkerMenuPoppedUp: false,
              isMenuPoppedUp: true
            };
          });
        } else if (coord.markers.length > 1) {
          if (this.state.isMultiMarkerMenuPoppedUp) {
            this.setState((prevState) => {
              return {
                isMultiMarkerMenuPoppedUp: false
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                isMultiMarkerMenuPoppedUp: true
              };
            });
          }
        }
      }
    }
  }

  setStateToMarkerSettings(marker) {
    if (marker) {
      if (marker.marker_type !== null) {
        this.setState((prevState) => {
          return {
            markerType: marker.marker_type.id
          };
        });
      }
      if (marker.aware_users) {
        this.setState((prevState) => {
          let users = [...prevState.awareUsers];
          marker.aware_users.forEach((user) => {
            users.push(user.id);
          });
          return {
            awareUsers: users
          };
        });
      }
      if (marker.public_notes) {
        this.setState((prevState) => {
          return {
            typedPublicNotesValue: marker.public_notes
          };
        });
      }
      if (marker.private_notes) {
        this.setState((prevState) => {
          return {
            typedPrivateNotesValue: marker.private_notes
          };
        });
      }
      if (marker.is_hiding) {
        this.setState((prevState) => {
          return {
            isHideToggled: true
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            isHideToggled: false
          };
        });
      }
    }
  }

  handleControlMarkerRightClick(e, marker) {
    e.preventDefault();
    this.closePopUp();
    this.setStateToMarkerSettings(marker);
    this.setState((prevState) => {
      return {
        rightClickedMarker: marker,
        isMultiMarkerMenuPoppedUp: false,
        isMenuPoppedUp: true
      };
    });
  }

  handleExpandedIconRightClick(e, marker) { // handles when expanded icons are clicked
    e.stopPropagation();
    e.preventDefault();
    this.closePopUp();
    this.setStateToMarkerSettings(marker);
    if (marker) {
      this.setState((prevState) => {
        return {
          rightClickedMarker: marker,
          isMenuPoppedUp: true
        };
      });
    }
  }


  handleExpandedIconClick(e, marker) {
    e.stopPropagation();
    if (
      this.state.boardSelectedMarker &&
      this.state.boardSelectedMarker.id === marker.id &&
      (
        this.props.currentUser.role ||
        (
          this.state.boardSelectedMarker.character &&
          this.state.boardSelectedMarker.character.creator_id === this.props.currentUser.id
        )
      )
    ) {
      this.setState((prevState) => {
        return {
          boardSelectedMarker: null,
          controlsSelectedMarker: null
        };
      });
    }
    // select a newly clicked marker
    else {
      if (
        this.props.currentUser.role ||
        (
          marker &&
          marker.character &&
          marker.character.creator_id === this.props.currentUser.id
        )
      ) {
        this.setState((prevState) => {
          return {
            boardSelectedMarker: marker,
            controlsSelectedMarker: null,
          };
        });
      }
    }
  }

  handleExpandedIconLeaveHover(e, marker) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({hoveredMarker: null});
  }

  handleExpandedIconHover(e, marker) {
    e.stopPropagation();
    e.preventDefault();
    if (marker) {
      if (
        (marker.name) ||
        (marker.character && marker.character.name)
      ) {
        this.setState({hoveredMarker: marker});
      }
    }
  }

  handleUserInclusionClick(e, userId) {
    e.preventDefault();
    // FUTURE FOR PERMISSIONS
    this.setState((prevState) => {
      let users = [...prevState.awareUsers];
      if (!users.includes(userId)) {
        users.push(userId)
      } else {
        users = users.filter(usr =>
          usr !== userId
        );
      }
      return {
        awareUsers: users,
      };
    });
  }

  handleMarkerTypeClick(e, markerType) {
    e.preventDefault();
    // FUTURE FOR PERMISSIONS
    this.setState((prevState) => {
      return {
        markerType: markerType,
      };
    });
  }

  toggleHideMarker() {
    this.setState({
      isHideToggled: !this.state.isHideToggled
    });
  }

  toggleDeleteMarker() {
    this.setState({
      isDeleteToggled: !this.state.isDeleteToggled
    });
  }

  toggleDeleteControlMarker() {
    this.setState({
      isDeleteControlToggled: !this.state.isDeleteControlToggled
    });
  }

  // handleMarkerColorPopUpClick(e) {
  //   e.preventDefault();
  //   this.setState({
  //     isColorOptionsMenuPoppedUp: true
  //   });
  // }

  // handleMarkerColorClick(e = null, code) {
  //   if (e !== null) {
  //     e.preventDefault();
  //   }
  //   this.setState({
  //     isColorOptionsMenuPoppedUp: false,
  //     selectedMenuHex: code
  //   });
  // }

  async handleLeaveHover(e, coord) {
    e.preventDefault();
    this.setState({hoveredMarker: null});
  }

  async handleHover(e, coord) {
    e.preventDefault();
    let hoveredMarker = null;
    // DO PERMISSIONS HERE
    if (coord.markers && coord.markers.length === 1) {
      let marker = coord.markers[0];
      if (
        (marker.name) ||
        (marker.character && marker.character.name)
      ) {
        hoveredMarker = marker;
      }
    }
    if (e.button === 2 || e.buttons === 2) {
      hoveredMarker = null;
      if (
        !this.state.dragFilledCoordinates.includes(coord.id) &&
        this.state.selectedColor
      ) {
        this.props.socket.emit("update coordinate", {
          mapId: this.state.campaignMap.id,
          colorHex: this.state.selectedColor.code,
          coordinateId: coord.id,
        });
      }
    }
    this.setState({hoveredMarker: hoveredMarker});
  }

  handleTypedNewHeightChange(e) {
    e.preventDefault();
    if (
      (
        parseInt(e.target.value) &&
        parseInt(e.target.value) > 0 &&
        parseInt(e.target.value) <= 40
      ) ||
      e.target.value === ""
    ) {
      this.setState({
        typedNewHeight: e.target.value
      });
    }
  }

  handleTypedNewWidthChange(e) {
    e.preventDefault();
    if (
      (
        parseInt(e.target.value) &&
        parseInt(e.target.value) > 0 &&
        parseInt(e.target.value) <= 40
      ) ||
      e.target.value === ""
    ) {
      this.setState({
        typedNewWidth: e.target.value
      });
    }
  }

  render() {

    // PLACEHOLDER FOR REQUESTING NEW MAP
    let newMapPopUp;
    let isUnclickable = "";
    if (
      !this.state.typedNewHeight ||
      !this.state.typedNewWidth
    ) {
      isUnclickable = 'is-unclickable';
    }
    if (this.state.isNewMapRequestPoppedUp) {
      newMapPopUp = <div className="pop-up">
                      <div className="new-map-pop-up pop-up-inner-confirmation">
                        <div className="new-map-warning">Warning! This will reset all work done on current map.</div>
                        <div className="new-map-hint">Tip: Choosing dimesions that stray further from a perfect square may hinder visual map experience.</div>
                        <div className="new-map-label-wrapper">
                          <div className="new-map-height">
                            <label className="campaign-detail-popup-input-field">
                              <input className="campaign-detail-popup-field" type='text' onChange={this.handleTypedNewHeightChange} value={this.state.typedNewHeight || ""} placeholder={'New Height (up to 40)'}/>
                            </label>
                          </div>
                          <div className="new-map-width">
                            <label className="campaign-detail-popup-input-field">
                              <input className="campaign-detail-popup-field" type='text' onChange={this.handleTypedNewWidthChange} value={this.state.typedNewWidth || ""} placeholder={'New Width (up to 40)'}/>
                            </label>
                          </div>
                        </div>
                        <div className="new-map-pop-up-button-container">
                          <div className={`popup-button pop-up-yes ${isUnclickable}`} onClick={this.submitNewMap}>
                            Submit
                          </div>
                          <div className="popup-button pop-up-no" type='submit' onClick={((e) => this.closePopUp(e))}>
                            Close
                          </div>
                        </div>
                      </div>
                    </div>;
    }

    let newMarkerPopUp;
    if (this.state.isNewMarkerRequestPoppedUp || this.state.rightClickedMarker) {
      newMarkerPopUp =  <CampaignMapMarkerPopUp
                          allUsers={this.props.allUsers}
                          currentUser={this.props.currentUser}
                          isNewRequest={this.state.isNewMarkerRequestPoppedUp}
                          rightClickedMarker={this.state.rightClickedMarker}
                          awareUsers={this.state.awareUsers}
                          markerType={this.state.markerType}
                          markerTypes={this.props.markerTypes}
                          // hex={hex}
                          // colorHexes={this.props.colorHexes}
                          // selectedMenuHex={this.state.selectedMenuHex}
                          nameContent={this.state.nameContent}
                          typedPublicNotesValue={this.state.typedPublicNotesValue}
                          typedPrivateNotesValue={this.state.typedPrivateNotesValue}
                          isColorOptionsMenuPoppedUp={this.state.isColorOptionsMenuPoppedUp}
                          isDeleteToggled={this.state.isDeleteToggled}
                          isDeleteControlToggled={this.state.isDeleteControlToggled}
                          isHideToggled={this.state.isHideToggled}
                          handleMarkerColorClick={this.handleMarkerColorClick}
                          handleNameChange={this.handleNameChange}
                          handleTypedPublicNotesValueChange={this.handleTypedPublicNotesValueChange}
                          handleTypedPrivateNotesValueChange={this.handleTypedPrivateNotesValueChange}
                          toggleDeleteMarker={this.toggleDeleteMarker}
                          toggleDeleteControlMarker={this.toggleDeleteControlMarker}
                          toggleHideMarker={this.toggleHideMarker}
                          handleMarkerTypeClick={this.handleMarkerTypeClick}
                          handleUserInclusionClick={this.handleUserInclusionClick}
                          // handleMarkerColorPopUpClick={this.handleMarkerColorPopUpClick}
                          onSubmit={this.handlePopUpSave}
                          closePopUp={this.closePopUp}
                        />
    }

    let campaignMapCoordinates;
    let campaignMapColumnIds;
    let campaignMapRowIds;
    let dynamicColumnStyling = {};
    let dynamicRowStyling = {};
    let dynamicMapStyling = {};
    let dynamicMapAndRowStyling = {};
    let dynamicRowCellStyling = {};
    let dynamicColumnCellStyling = {};
    if (this.state.campaignMap) {
      let rowSize = this.state.campaignMap.rows_count > 10
        ? 250 / this.state.campaignMap.rows_count
        : 25;
      let rowFontSize = `${rowSize}px`;
      let columnSize = this.state.campaignMap.columns_count > 10
        ? 250 / this.state.campaignMap.columns_count
        : 25;
      let columnFontSize = `${columnSize}px`;

      let heightRatio = 0;
      let widthRatio = 0;
      //do stuff to widthRatio
      if (this.state.campaignMap.columns_count > this.state.campaignMap.rows_count) {
        widthRatio = 10*(this.state.campaignMap.columns_count / this.state.campaignMap.rows_count);
      }
      //do stuff to heightRatio
      else if (this.state.campaignMap.columns_count < this.state.campaignMap.rows_count) {
        heightRatio = 10*(this.state.campaignMap.rows_count / this.state.campaignMap.columns_count);
      }

      dynamicMapStyling = {
        gridTemplateColumns: `repeat(${this.state.campaignMap.columns_count}, 1fr)`,
        outline: `#333 solid`,
        width: `calc(${((this.state.campaignMap.columns_count-1)/this.state.campaignMap.columns_count)*100}% - ${widthRatio}px)`
      };
      dynamicColumnStyling = {
        gridTemplateColumns: `repeat(${this.state.campaignMap.columns_count}, 1fr)`,
        height: `calc(${(1/this.state.campaignMap.rows_count)*100}% + ${heightRatio}px)`,
        marginLeft: `calc(${(1/this.state.campaignMap.columns_count)*100}% + ${widthRatio}px)`,
        width: `calc(${((this.state.campaignMap.columns_count-1)/this.state.campaignMap.columns_count)*100}% - ${widthRatio}px)`
      };
      dynamicRowStyling = {
        width: `calc(${(1/this.state.campaignMap.columns_count)*100}% + ${widthRatio}px)`,
      };
      dynamicMapAndRowStyling = {
        height: `calc(${((this.state.campaignMap.rows_count-1)/this.state.campaignMap.rows_count)*100}% - ${heightRatio}px)`,
      };
      dynamicRowCellStyling = {
        fontSize: rowFontSize,
        gridColumn: `${(1/this.state.campaignMap.columns_count)*100}%`
      };
      dynamicColumnCellStyling = {
        fontSize: columnFontSize,
        gridColumn: `${(1/this.state.campaignMap.columns_count)*100}%`
      };

      let column_alphabet = [ // up to 40 (for map columns)
          'A','B','C','D','E','F','G','H','I','J',
          'K','L','M','N','O','P','Q','R','S','T',
          'U','V','W','X','Y','Z','AA','AB','AC','AD',
          'AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN',
          // 'AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX',
          // 'AY','AZ','BA','BB','BC','BD','BE','BF','BG','BH',
          // 'BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR',
          // 'BS','BT','BU','BV','BW','BX','BY','BZ','CA','CB',
          // 'CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL',
          // 'CM','CN','CO','CP','CQ','CR','CS','CT','CU','CV'
      ];
      campaignMapColumnIds = [...Array(this.state.campaignMap.columns_count)].map((e, i) => {
        return  <div className="map-column-identifier" style={dynamicColumnCellStyling} key={i}>
                  {column_alphabet[i]}
                </div>;
      });
      campaignMapRowIds = [...Array(this.state.campaignMap.rows_count)].map((e, i) => {
        return  <div className="map-row-identifier" style={dynamicRowCellStyling} key={i}>
                  {i+1}
                </div>;
      });
      campaignMapCoordinates = this.state.campaignMap.coordinates.map((coordinate, index) => {
        return  <CampaignMapCoordinate
                  key={index}
                  handleCoordinateClick={this.handleCoordinateClick}
                  handleCoordinateRightClick={this.handleCoordinateRightClick}
                  handleExpandedIconClick={this.handleExpandedIconClick}
                  handleExpandedIconRightClick={this.handleExpandedIconRightClick}
                  handleExpandedIconHover={this.handleExpandedIconHover}
                  handleExpandedIconLeaveHover={this.handleExpandedIconLeaveHover}
                  handleHover={this.handleHover}
                  handleLeaveHover={this.handleLeaveHover}
                  handleStopFill={this.handleStopFill}
                  width={this.state.campaignMap.columns_count}
                  height={this.state.campaignMap.rows_count}
                  coordinate={coordinate}
                  boardSelectedMarker={this.state.boardSelectedMarker}
                  hoveredMarker={this.state.hoveredMarker}
                  isMultiMarkerMenuPoppedUp={this.state.isMultiMarkerMenuPoppedUp}
                />
      });
    }

    let rendering;
    if (this.state.loading !== false) return <Loader/>;
    let loader;
    if (this.state.loading) {
      loader = <i className="fa fa-cog fa-spin" />;
      dynamicMapStyling['display'] = 'flex';
      dynamicMapStyling['justifyContent'] = 'center';
      dynamicMapStyling['alignItems'] = 'center';
    }

    let campaignMapInitiative =
      <div className="campaign-map-initiative">
        Initiative-Rolling Section Coming Soon!
      </div>


    if (false) {
      rendering = <div>Coming soon!</div>;
    } else {
      rendering = <div className="campaign-map-inner">
                    {newMapPopUp}
                    {newMarkerPopUp}
                    <div className="campaign-map-and-initiative">
                      <div className="campaign-map-coordinates-outer-wrapper">
                        <div className="campaign-map-coordinates campaign-map-coordinates-columns-ids" style={dynamicColumnStyling}>
                          {campaignMapColumnIds}
                        </div>
                        <div className="campaign-map-coordinates-inner-wrapper" style={dynamicMapAndRowStyling}>
                          <div className="campaign-map-coordinates campaign-map-coordinates-rows-ids" style={dynamicRowStyling}>
                            {campaignMapRowIds}
                          </div>
                          <div className="campaign-map-coordinates" style={dynamicMapStyling}>
                            {loader}
                            {campaignMapCoordinates}
                          </div>
                        </div>
                      </div>
                      {campaignMapInitiative}
                    </div>
                    <div className="campaign-map-controls">
                      <CampaignMapControls
                        currentUser={this.props.currentUser}
                        selectHexColor={this.selectHexColor}
                        selectMapMarker={this.selectMapMarker}
                        selectMapIndicator={this.selectMapIndicator}
                        requestNewMap={this.requestNewMap}
                        createNewMarkerClick={this.createNewMarkerClick}
                        handleControlMarkerRightClick={this.handleControlMarkerRightClick}
                        controlsSelectedMarker={this.state.controlsSelectedMarker}
                        controlsSelectedIndicator={this.state.controlsSelectedIndicator}
                        selectedColor={this.state.selectedColor}
                        colorHexes={this.props.colorHexes}
                        // markerHexes={this.props.markerHexes}
                        campaignMap={this.state.campaignMap}
                        campaign={this.state.campaign}
                        controlsMarkers={this.state.controlsMarkers}
                      />
                    </div>
                  </div>;
    }

    return(
      <div className="campaign-map">
        {rendering}
      </div>
    )
  }
}

export default CampaignMap
