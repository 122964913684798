import React, { Component } from 'react';
import ConfirmationPopup from '../components/ConfirmationPopup';
import PlayerPopUp from '../containers/PlayerPopUp';
import CampaignActions from './CampaignActions';
import CampaignFieldView from './CampaignFieldView';
// import CampaignInfo from './CampaignInfo';
import CampaignHome from './CampaignHome';
import CampaignCharSheet from './CampaignCharSheet';
import CampaignNav from './CampaignNav'

class CampaignPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSection: null,
      popUp: false,
      pcPopUp: false,
      pcRequestPopUp: false,
      pcRemovePopUp: false,
      publicPopUp: false,
      campaign: null,
      users: null,
      currentUser: null,
      allUsers: null,
      msg: "",
      level: null,
      actionType: null,
      selectedUser: null,
      file: null,
      preview: null,
      navCollapsed: true,
      navHovered: false,
      typedQuestNotesValue: "",
      loading: true,
    };
    this.togglePopUp = this.togglePopUp.bind(this);
    this.toggleChangePCPopUp = this.toggleChangePCPopUp.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleChangePlayer = this.handleChangePlayer.bind(this);
    this.changeCampaign = this.changeCampaign.bind(this);
    this.togglePublicPopUp = this.togglePublicPopUp.bind(this);
    this.handleChangePublic = this.handleChangePublic.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCollapseButtonClick = this.onCollapseButtonClick.bind(this);
    this.handleCollapseHover = this.handleCollapseHover.bind(this);
    this.handleCollapseUnHover = this.handleCollapseUnHover.bind(this);
    this.handleImageReset = this.handleImageReset.bind(this);
    this.handleImageSave = this.handleImageSave.bind(this);
    this.handleTypedQuestNotesValueChange = this.handleTypedQuestNotesValueChange.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
  }

  componentDidMount() {
    this.getCampaign();
  }

  async getCampaign() {
    const response = await fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
          credentials: 'same-origin',
          method: 'GET',
          headers: { 'Content-Type':'application/json'}
        });
    const json = await response.json();
    if(response.status === 200) {
      this.setState((prevState) => {
        return {
          campaign: json.campaign,
          level: json.level,
          users: json.users,
          currentUser: json.current_user,
          allUsers: json.all_users,
          file: null,
          preview: null,
          typedQuestNotesValue: json.campaign.quest_notes,
          loading: false
        };
      });
    } else {
      this.props.pop(json.msg, json.status);
    }
  }

  togglePopUp() {
    this.setState({popUp: !this.state.popUp});
  }

  handleClose(e) {
    e.preventDefault();
    this.closePopUp();
  }

  closePopUp() {
    this.setState({
      popUp: false,
      pcPopUp: false,
      pcRequestPopUp: false,
      pcRemovePopUp: false,
      publicPopUp: false
    });
  }

  toggleChangePCPopUp(e, actionType, user) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        selectedUser: user,
        actionType: actionType
      };
    });
    if (actionType === "remove") {
      this.setState((prevState) => {
        return {
          pcRemovePopUp: !this.state.pcRemovePopUp
        };
      });
    } else if (actionType === "force_add" || actionType === "request_add") {
      this.setState((prevState) => {
        return {
          pcPopUp: !this.state.pcPopUp
        };
      });
    }
  }

  handleChangePlayer(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('username', value.username);
    formPayload.append('type', value.actionType);
    this.changeCampaign(formPayload);
    this.closePopUp();
  }

  changeCampaign(formPayload) {
    fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload,
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.getCampaign();
      }
    }));
  }

  togglePublicPopUp(e) {
    e.preventDefault();
    // console.log("inside ::: togglePublicPopUp");
    let action;
    if (this.state.campaign.public === true) {
      action = "make_private";
    } else {
      action = "make_public";
    }
    this.setState({
      publicPopUp: !this.state.publicPopUp,
      actionType: action
    });
  }

  handleChangePublic(e, value) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('type', value.actionType);
    this.changeCampaign(formPayload);
    this.closePopUp();
  }

  handleDelete(e, value) {
    e.preventDefault();
    this.deleteCampaign();
    this.closePopUp();
  }

  deleteCampaign() {
    fetch(`/api/v1/campaigns/${this.state.campaign.id}`, {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.props.history.push("/campaigns");
      }
    }));
  }

  handleSectionClick(e, sectionName) {
    e.preventDefault();
    this.getCampaign();
    this.setState({
      selectedSection: sectionName
    });
  }

  handleChange(e) {
    let file = e.target.files[0];
    this.setState({file: file});
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({
        preview: [reader.result]
      });
    }.bind(this);
  }

  handleImageReset(e) {
    e.preventDefault();
    // this.imgRef.current.value = "";
    this.setState((preState) => {
      return {
        file: null,
        preview: null,
      };
    });
    this.getCampaign();
  }

  handleImageSave(e) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('type', "upload_photo");
    formPayload.append('file', this.state.file);
    formPayload.append('genre', 'campaigns');
    formPayload.append('category', 'campaign');
    formPayload.append('id', this.state.campaign.id);
    fetch(`/api/v1/campaigns/${this.props.match.params.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        // console.log("success");
        this.getCampaign();
      } else {
        // this.props.history.push("/edit_profile/" + this.props.match.params.id);
      }
    }));
  }

  handleCollapseHover(e) {
    e.preventDefault();
    this.setState({ navHovered: true });
  }

  handleCollapseUnHover(e) {
    e.preventDefault();
    this.setState({ navHovered: false });
  }


  onCollapseButtonClick(e) {
    e.preventDefault();
    this.setState({ navCollapsed: !this.state.navCollapsed });
  }

  handleTypedQuestNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 4000) {
      this.setState((prevState) => {
        return {
          typedQuestNotesValue: val
        };
      });
    }
  }

  saveNotes(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    let formPayload = new FormData();
    formPayload.append('campaignNotes', this.state.typedQuestNotesValue);
    fetch(`/api/v1/campaign/${this.state.campaign.id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let campaign = Object.assign({}, prevState.campaign);
          campaign.quest_notes = res.body.campaign.quest_notes
          return {
            campaign: campaign,
            loading: false
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            loading: false
          };
        });
        this.props.pop(res.body.msg, res.body.status);
      }
    }));

  }

  render() {
    let excludedNames;
    if (this.state.allUsers) {
      excludedNames = this.state.allUsers.map(user => user.username);
    }

    let publicMessage = this.state.campaign && this.state.campaign.public ?
      "private?" :
      "public? Your campaign will become visible to all other users."

    let shownPopUp =  <ConfirmationPopup
                        msg="Are you sure you want to delete this Campaign? All members of this Campaign will also be removed."
                        onSubmit={this.handleDelete}
                        closePopUp={this.handleClose}
                      />

    let shownRemovePcPopUp =  <ConfirmationPopup
                                actionType={this.state.actionType}
                                username={this.state.selectedUser}
                                msg={`Are you sure you want to remove ${this.state.selectedUser} from the campaign?`}
                                onSubmit={this.handleChangePlayer}
                                closePopUp={this.handleClose}
                              />

    // In the future, disable the search capabilities to protect users and processing speed
    let shownPcPopUp =  <PlayerPopUp
                          message='Invite a user'
                          users={excludedNames}
                          onSubmit={this.handleChangePlayer}
                          actionType={this.state.actionType}
                          closePopUp={this.handleClose}
                        />

    let shownPublicPopUp =  <ConfirmationPopup
                              msg={`Are you sure you want to make this campign ${publicMessage}`}
                              onSubmit={this.handleChangePublic}
                              actionType={this.state.actionType}
                              closePopUp={this.handleClose}
                            />

    let section;
    if (this.state.selectedSection === 'campaignActions') {
      section = <CampaignActions
                  collapsed={this.state.collapsed}
                  campaign={this.state.campaign}
                  currentUser={this.state.currentUser}
                  preview={this.state.preview}
                  users={this.state.users}
                  allUsers={this.state.allUsers}
                  level={this.state.level}
                  togglePopUp={this.togglePopUp}
                  toggleChangePCPopUp={this.toggleChangePCPopUp}
                  toggleRequestPCPopUp={this.toggleRequestPCPopUp}
                  togglePublicPopUp={this.togglePublicPopUp}
                  handleChange={this.handleChange}
                  onSave={this.handleImageSave}
                  resetFields={this.handleImageReset}
                  ref={this.imgRef}
                />
    } else if (this.state.selectedSection === 'fieldView') {
      section = <CampaignFieldView
                  campaign={this.state.campaign}
                  currentUser={this.state.currentUser}
                  allUsers={this.state.allUsers}
                />
    } else if (
      this.state.campaign &&
      (
        this.state.selectedSection === 'charSheet'
        // ||
        // localStorage.getItem('toChar') === 'true'
      )
    ) {
      section = <CampaignCharSheet
                  currentUser={this.state.currentUser}
                  getCampaign={this.getCampaign}
                  campaign={this.state.campaign}
                  users={this.state.allUsers}
                  level={this.state.level}
                  pop={this.props.pop}
                />
    // } else if (this.state.selectedSection === 'logs') {
    //   section = <CampaignInfo />
    } else {
      section = <CampaignHome
                  campaign={this.state.campaign}
                  users={this.state.allUsers}
                  level={this.state.level}
                  loading={this.state.loading}
                  onSave={this.saveNotes}
                  typedQuestNotesValue={this.state.typedQuestNotesValue}
                  handleTypedQuestNotesValueChange={this.handleTypedQuestNotesValueChange}
                />
    }

    const sections = [
      // {'code': 'home', 'name': 'Home', 'symbol': 'fa-house' },
      {'code': 'home', 'name': 'Home', 'symbol': 'fa-users' },
      {'code': 'charSheet', 'name': 'Char Sheet', 'symbol': 'fa-user' },
      // {'code': 'logs', 'name': 'Campaign Info', 'symbol': 'fa-users' },
      {'code': 'fieldView', 'name': 'Field View', 'symbol': 'fa-street-view' },
      // {'code': 'fieldView', 'name': '**Future Section**', 'symbol': 'fa-question' },
      // {'code': 'campaignChat', 'name': 'Chat', 'symbol': 'fa-comment-dots' }
    ]
    if (this.state.level === 'dm') {
      sections.push({'code': 'campaignActions', 'name': 'Settings', 'symbol': 'fa-gear' });
    }

    let collapsedNav = this.state.navCollapsed ? "campaign-nav-collapsed" : "";
    let collapsedSection = this.state.navCollapsed ? "campaign-section-collapsed" : "";

    let nav = <CampaignNav
                handleCollapseHover={this.handleCollapseHover}
                handleCollapseUnHover={this.handleCollapseUnHover}
                sections={sections}
                hovered={this.state.navHovered}
                collapsed={this.state.navCollapsed}
                level={this.state.level}
                onCollapseButtonClick={this.onCollapseButtonClick}
                selectedSection={this.state.selectedSection}
                handleSectionClick={this.handleSectionClick}
              />

    return(
      <div className="wholePage campaign-page" id="container">
        <div className="campaign-page">
          <div className={`campaign-nav ${collapsedNav}`}>
            {nav}
          </div>
          <div className={`campaign-section-container ${collapsedSection}`}>
            {section}
          </div>
        </div>
        {this.state.popUp ? shownPopUp : null}
        {this.state.pcPopUp ? shownPcPopUp : null}
        {this.state.pcRemovePopUp ? shownRemovePcPopUp : null}
        {this.state.publicPopUp ? shownPublicPopUp : null}
      </div>
    )
  }
}

export default CampaignPage
