import React, { Component } from 'react';
import { io } from 'socket.io-client';

// import { connect } from 'react-redux';
//
// import { actions } from '../../store'
import CampaignMap from './CampaignMappingSandbox/CampaignMap';
import Loader from '../components/Loader';

let socket;

class CampaignFieldView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      campaignMap: null,
      controlsMarkers: [],
      colorHexes: [],
      markerHexes: [],
      markerTypes: [],
      loading: true,
      disconnections: 0
    }
    this.connectToSocket = this.connectToSocket.bind(this);
    // this.getMapDetails = this.getMapDetails.bind(this);
  }

  componentDidMount() {
    this.connectToSocket();

    socket.on("successful connection", (data) => {
      if (this.state.diconnections <= 1) {
        socket.disconnect()
        this.setState({ disconnections: this.state.disconnections + 1 });
        this.connectToSocket();
      } else {
        socket.emit("get map", { campaignId: this.props.campaign.id });
      }
      // var iterations = 5;
      // var interval = 2000;
      // for (var i = 0; i < iterations; i++) {
      //     setTimeout(function () {
      //       socket.disconnect()
      //       this.connectToSocket();
      //     }, i * interval)
      // }
    })

    socket.on("get map", (serverData) => {
      let data = JSON.parse(serverData);
      this.setState({
        campaignMap: data.campaign_map,
        controlsMarkers: data.markers,
        markerHexes: data.marker_hexes,
        colorHexes: data.color_hexes,
        markerTypes: data.marker_types,
        loading: false
      });
    })

  }

  componentWillUnmount() {
    // when component unmounts, disconnect
    console.log("closing websocket...");
    socket.disconnect()
  }

  connectToSocket() {
    let server = undefined;
    const domain = window.location.origin;
    if (window.location.hostname === 'localhost') {
      server = `http://${window.location.hostname}:3000`;
    }

    socket = io(server, {
      cors: {
          origin: domain,
          methods: ["GET", "POST"]
      },
      transports: ['websocket', 'polling'],
      withCredentials: true,
      allowEIO3: true
    });
  }

  // getMapDetails() {
  //   socket.emit("get map", { campaignId: this.props.campaign.id });
  // }

  // async componentDidMount() {
  //   await this.getMapDetails();
  // }
  //
  // async getMapDetails() {
  //   const response = await fetch(`api/v1/campaign_map_details/${this.props.campaign.id}`, {
  //         credentials: 'same-origin',
  //         method: 'POST',
  //         headers: { 'Content-Type':'application/json'}
  //       });
  //   const json = await response.json();
  //   if(response.status === 200) {
  //     this.setState({
  //       campaignMap: json.campaign_map,
  //       controlsMarkers: json.markers,
  //       markerHexes: json.marker_hexes,
  //       colorHexes: json.color_hexes,
  //       markerTypes: json.marker_types,
  //       loading: false
  //     });
  //   } else {
  //     this.setState({
  //       campaignMap: json.campaign_map,
  //       controlsMarkers: [],
  //       markerHexes: [],
  //       colorHexes: [],
  //       markerTypes: [],
  //       loading: false
  //     });
  //   }
  // }


  render() {
    let mapRender = this.props.currentUser
      ? <div className="campaign-field-view-map-section">
          <CampaignMap
            campaign={this.props.campaign}
            campaignMap={this.state.campaignMap}
            currentUser={this.props.currentUser}
            allUsers={this.props.allUsers}
            controlsMarkers={this.state.controlsMarkers}
            markerHexes={this.state.markerHexes}
            colorHexes={this.state.colorHexes}
            markerTypes={this.state.markerTypes}
            socket={socket}
          />
        </div>
      : <div className="campaign-char-sheet-characters-wrapper">
          <div className="campaign-char-sheet-characters-alt">
            <div className="campaign-char-sheet-characters-heading-alt">
              Only members of this campaign can access this information.
            </div>
          </div>
        </div>;


    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;
    return (
      <div className="campaign-field-view-container">
        {loader}
        {mapRender}
      </div>
    )
  }
}

export default CampaignFieldView

// getMapDetails={this.getMapDetails}

//   async componentDidMount() {
//     await this.props.getMapDetails(this.props.campaign.id);
//   }
//
//   render() {
//     if (this.props.loading !== false) return <Loader/>;
//     const loader = this.props.loading ? <i className="fa fa-cog fa-spin" /> : null;
//
//     return(
//       <div className="campaign-field-view-container">
//         {loader}
//         <div className="campaign-field-view-map-section">
//           <CampaignMap
//             campaign={this.props.campaign}
//             campaignMap={this.props.campaignMap}
//             controlsMarkers={this.props.controlsMarkers}
//             markerHexes={this.props.markerHexes}
//             colorHexes={this.props.colorHexes}
//           />
//         </div>
//       </div>
//     )
//   }
// }
//
// function mapStateToProps(state) {
//   return {
//     campaignMap: state.campaignMap,
//     colorHexes: state.colorHexes,
//     controlsMarkers: state.controlsMarkers,
//     markerHexes: state.markerHexes,
//     loading: state.loading
//   };
// }
//
// function mapDispatchToProps(dispatch) {
//   return {
//     async getMapDetails(campaignId) {
//       const response = await fetch(`api/v1/campaign_map_details/${campaignId}`, {
//             credentials: 'same-origin',
//             method: 'POST',
//             headers: { 'Content-Type':'application/json'}
//           });
//       const json = await response.json();
//       const mapDetails = response.status === 200 ?
//         {
//           campaignMap: json.campaign_map,
//           controlsMarkers: json.markers,
//           markerHexes: json.marker_hexes,
//           colorHexes: json.color_hexes,
//           loading: false
//         } : {
//           campaignMap: null,
//           controlsMarkers: [],
//           markerHexes: [],
//           colorHexes: [],
//           loading: false
//         };
//       dispatch(actions.getMapDetails(mapDetails));
//     }
//   }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(CampaignFieldView);
