import React, { Component } from 'react';
import Nav from '../components/Nav';
import Main from '../components/Main';
import SignedOutNavBar from '../components/SignedOutNavBar';
import SignedInNavBar from '../components/SignedInNavBar';
import AdminNavBar from '../components/AdminNavBar';
import Popup from '../components/Popup';
import Loader from '../components/Loader';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      signedIn: false,
      admin: false,
      owner: false,
      popup: false,
      timeouts: [],
      message: "",
      status: null,
      dropdownCollapsed: true,
      loading: false,
      typedValue: "",
    };
    this.displayUser = this.displayUser.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.closePop = this.closePop.bind(this);
    this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
    this.handleClicked = this.handleClicked.bind(this);
    this.handleTypedValueChange = this.handleTypedValueChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.checkValue = this.checkValue.bind(this);
  }

  async componentDidMount() {
    await this.displayUser();
  }

  async displayUser(resetType=null) {
    let url = '/api/v1/current_user';
    const response = await fetch(url, {
          credentials: 'same-origin',
          method: 'GET',
          headers: { 'Content-Type':'application/json'}
        });
    const json = await response.json();

    // .then(response =>
    //   response.json().then(body => ({
    //     body: body,
    //     status: response.status
    //   })
    // ).then(res => {
    if(response.status === 200) {
      if (resetType === null) {
        this.setState({
          signedIn: true,
          currentUser: json,
          admin: json.admin,
          owner: json.owner
        });
      // } else if (resetType === 'notifications') {
      //   this.setState((prevState) => {
      //     let user = Object.assign({}, prevState.currentUser);
      //     user.is_unread = json.is_unread
      //     return { user };
      //   });
      } else {
        this.setState({
          signedIn: true,
          currentUser: json,
          admin: json.admin,
          owner: json.owner
        });
      }
    } else {
      this.setState({
        signedIn: false,
        currentUser: null,
        admin: false,
        owner: false
      });
    }
    // }));
  }

  togglePop(message, status) {
    this.closePop();
    this.setState((prevState) => {
      return {
        popup: true,
        message: message,
        status: status
      };
    });
    setTimeout(function() {
      this.setState((prevState) => {
        return {
          popup: false,
          message: "",
          status: null
        }
      });
    }.bind(this), 5000);
  }

  closePop() {
    this.setState((prevState) => {
      return {
        popup: false,
        message: "",
        status: null
      };
    });
    clearTimeout();
  }

  toggleUserDropdown() {
    const status = this.state.dropdownCollapsed;
    this.setState({ dropdownCollapsed: !status });
  }

  handleClicked() {
    if (!this.state.dropdownCollapsed) {
      this.setState({ dropdownCollapsed: true });
    }
  }

  handleTypedValueChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        typedValue: val
      };
    });
  }

  handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter' && this.state.typedValue) {
      this.setState((prevState) => {
        return {
          typedValue: "",
          loading: true
        };
      });
      this.checkValue(this.state.typedValue);
    }
  }

  checkValue(value) {
    let formPayload = new FormData();
    formPayload.append('value', value);
    fetch('/api/v1/check_entry_key', {
      credentials: 'same-origin',
      method: 'POST',
      body: formPayload,
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if (res.status === 200) {
        localStorage.setItem('masked', JSON.stringify(false));
        this.setState((prevState) => { return { loading: false }; });
      } else {
        this.setState((prevState) => { return { loading: false }; });
        this.togglePop(res.body.msg, res.body.status);
      }
    }));
  }

  render() {

    let user_signed_in;
    if(!this.state.signedIn){
        user_signed_in = <SignedOutNavBar />
    } else {
      if(this.state.currentUser.role === "admin"){
        user_signed_in = <AdminNavBar />
      } else {
        user_signed_in = <SignedInNavBar
                            pop={this.togglePop}
                            user={this.state.currentUser}
                            displayUser={this.displayUser}
                            dropdownCollapsed={this.state.dropdownCollapsed}
                            toggleUserDropdown={this.toggleUserDropdown}
                            handleClicked={this.handleClicked}
                         />
      }
    }


    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;

    // let isMasked = localStorage.getItem('masked') !== 'false' ? true : false;
    let masked;
    // if (isMasked) {
    //   masked =  <div className="screen-mask">
    //               <label className="screen-mask-input">
    //                 <input
    //                   className="screen-mask-input-text"
    //                   type='password'
    //                   onChange={this.handleTypedValueChange}
    //                   onKeyPress={event => {if (event.key === 'Enter') {return this.handleKeyPress(event)}}}
    //                   value={this.state.typedValue}
    //                   placeholder="Enter access key"
    //                 />
    //               </label>
    //             </div>
    // }
    let isMasked = false;

    return(
      <div className="application" onClick={this.handleClicked}>
        {masked}
        {loader}
        {this.state.popup ? <Popup toggle={this.closePop} messageText={this.state.message} messageStatus={this.state.status}/> : null}
        {isMasked ? null :
          <Nav
            onClick={this.closePop}
            user_signed_in={user_signed_in}
          />
        }
        {isMasked ? null :
          <Main
            onClick={this.closePop}
            signedIn={this.state.signedIn}
            currentUser={this.state.currentUser}
            admin={this.state.admin}
            pop={this.togglePop}
            displayUser={this.displayUser}
          />
        }
      </div>
    )
  }
}

export default App
