import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import UserDropdown from './UserDropdown.js';
import exclamation from "../../icons/exclamation.png";


class SignedInNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.endSession = this.endSession.bind(this);
  }


  async handleSubmit(event) {
    await this.endSession();
  }

  async endSession() {
    let url = "/api/v1/logout";
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' }
    });
    const json = await response.json();
    this.props.history.push("/login");
    await this.props.displayUser("notifications");
    // window.location.reload();
    if (response.status === 200) {
      this.props.pop(json.msg, json.status);
    }
  }

  render() {
    let displayName = this.props.user.display_name;
    if (displayName.length >= 14) {
      displayName = displayName.substring(0,14) + "...";
    }

    let dropdown = this.props.dropdownCollapsed ? null
    : <UserDropdown
        user={this.props.user}
        handleClick={this.props.handleClicked}
        handleSubmit={this.handleSubmit}
      />

    let unreadElement = this.props.user.is_unread
      ? <img className="unread-notifications-icon" src={exclamation} alt=""/>
      : null

    return(
      <div className="navMeasurement right credentials">
        <div className="navText2" onClick={this.props.toggleUserDropdown}>
          <img className="navIcon navPiece" src={this.props.user.photo} alt="profile icon" />
          <div className="navPiece">
            {displayName}
            {unreadElement}
          </div>
        </div>
        {dropdown}
      </div>
    )
  }

}

export default withRouter(SignedInNavBar)
