import React from 'react';

function createStylingForMarker (marker, selectedMarker) {
  let boxShadow = selectedMarker === marker.id ? 'inset 0px 0px 2px 2px #EEE' : 'none';
  let dynamicStyling = marker.map_coordinate_id !== null
    ? {
        pointerEvents: 'auto',
        cursor: 'auto',
        boxShadow: boxShadow,
        background: '#7A7363',
        fontStyle: 'italic'
      }
    : {
        pointerEvents: 'auto',
        cursor: 'pointer',
        boxShadow: boxShadow
      };
  let name = marker.character ? `${marker.character.name}` : `${marker.name}`;
  let iconName = <i className={`fa-solid ${marker.marker_type.icon_name}`}></i>;
  return {
    dynamicStyling: dynamicStyling,
    iconName: iconName,
    name: name
  }
}

const CampaignMapControls = props => {

  let isDm = props.currentUser && props.currentUser.role === true ? true : false;

  let markerText = isDm ? "Select Map Marker (R-Click to view Details)" : "Map Legend (R-Click to view Details)"

  // let color_hexes = [
  //   {'id': 0, 'name': 'Boundary', 'code': '303030'},
  //   {'id': 1, 'name': 'Fog of War', 'code': '606060'},
  //   {'id': 2, 'name': 'Water', 'code': '484890'},
  // ];

  // let colorHexes = props.markerHexes;

  let colorSection;
  if (isDm) {
    let colorHexes = props.colorHexes ? props.colorHexes : [];
    let hexButtons = colorHexes.map((hex) => {
      let boxShadow = props.selectedColor && props.selectedColor.code === hex.code ? 'inset 0px 0px 2px 2px #EEE' : 'none';
      let dynamicStyling = {
        background: `#${hex.code}`,
        boxShadow: boxShadow
      }
      return  <div
                className="hex-control"
                key={hex.id}
              >
                <div
                  className="hex-control-preview-square"
                  style={dynamicStyling}
                  onClick={((e) => props.selectHexColor(e, hex))}
                ></div>
              </div>
    });
    colorSection =  <div className="map-controls-remaining-control">
                      <div className="control-header">Map Fill Controls</div>
                      <div className="control-header">(Color Map with R-Click)</div>
                      <div className="hex-controls">
                        {hexButtons}
                      </div>
                    </div>;
  } else {
    colorSection = <div className="map-controls-remaining-control"></div>;
  }



  let pcMarkers = [];
  let npcMarkers = [];
  let enemyMarkers = [];
  let lootMarkers = [];
  let trapMarkers = [];
  let miscMarkers = [];
  let controlsMarkers = props.controlsMarkers === undefined ? [] : props.controlsMarkers;
  controlsMarkers.forEach((marker) => {
    if (marker.marker_type.name === 'PC') {
      pcMarkers.push(marker)
    }
    if (marker.marker_type.name === 'NPC') {
      npcMarkers.push(marker)
    }
    if (marker.marker_type.name === 'Enemy') {
      enemyMarkers.push(marker)
    }
    if (marker.marker_type.name === 'Loot') {
      lootMarkers.push(marker)
    }
    if (marker.marker_type.name === 'Trap') {
      trapMarkers.push(marker)
    }
    if (marker.marker_type.name === 'Misc.') {
      miscMarkers.push(marker)
    }
  })

  let selectedMarker = props.controlsSelectedMarker ? props.controlsSelectedMarker.id : null
  let pcMarkerDivs = pcMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    let ownerName =
      marker &&
      marker.character &&
      marker.character.creator_name
      ? ` [${marker.character.creator_name}]`
      : "";
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{`${updatedInfo.name}${ownerName}`}</div>
            </div>
  });

  let npcMarkerDivs = npcMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{updatedInfo.name}</div>
            </div>
  });

  let enemyMarkerDivs = enemyMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{updatedInfo.name}</div>
            </div>
  });

  let lootMarkerDivs = lootMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{updatedInfo.name}</div>
            </div>
  });

  let trapMarkerDivs = trapMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{updatedInfo.name}</div>
            </div>
  });

  let miscMarkerDivs = miscMarkers.map((marker) => {
    let updatedInfo = createStylingForMarker(marker, selectedMarker);
    return  <div
              className="marker-control"
              key={marker.id}
              style={updatedInfo.dynamicStyling}
              onClick={((e) => props.selectMapMarker(e, marker))}
              onContextMenu={((e) => props.handleControlMarkerRightClick(e, marker))}
            >
              <div className="marker-control-preview-square">{updatedInfo.iconName}</div>
              <div className="marker-control-preview-square-text">{updatedInfo.name}</div>
            </div>
  });

  let indicatorBoxShadow = props.controlsSelectedIndicator ? 'inset 0px 0px 2px 2px #EEE' : 'none';
  let dynamicIndicatorStyling = {
    pointerEvents: "auto",
    cursor: "pointer",
    background: "#708090",
    boxShadow: indicatorBoxShadow
  }

  let pcDivStyles = pcMarkerDivs.length === 0 ? "no-border" : "";
  let npcDivStyles = npcMarkerDivs.length === 0 ? "no-border" : "";
  let enemyDivStyles = enemyMarkerDivs.length === 0 ? "no-border" : "";
  let lootDivStyles = lootMarkerDivs.length === 0 ? "no-border" : "";
  let trapDivStyles = trapMarkerDivs.length === 0 ? "no-border" : "";
  let miscDivStyles = miscMarkerDivs.length === 0 ? "no-border" : "";

  let mapMarkers =
    <div className='controls-map-markers-container'>
      <div className='controls-map-markers-grouping'>
        <div className='controls-map-markers-grouping-header'>
          PCs and NPCs
        </div>
        <div className={`controls-map-markers-type-section ${pcDivStyles}`}>
          {pcMarkerDivs}
        </div>
        <div className={`controls-map-markers-type-section ${npcDivStyles}`}>
          {npcMarkerDivs}
        </div>
      </div>
      <div className='controls-map-markers-grouping'>
        <div className='controls-map-markers-grouping-header'>
          Enemies
        </div>
        <div className={`controls-map-markers-type-section ${enemyDivStyles}`}>
          {enemyMarkerDivs}
        </div>
      </div>
      <div className='controls-map-markers-grouping'>
        <div className='controls-map-markers-grouping-header'>
          Loot, Traps, & Misc.
        </div>
        <div className={`controls-map-markers-type-section ${lootDivStyles}`}>
          {lootMarkerDivs}
        </div>
        <div className={`controls-map-markers-type-section ${trapDivStyles}`}>
          {trapMarkerDivs}
        </div>
        <div className={`controls-map-markers-type-section ${miscDivStyles}`}>
          {miscMarkerDivs}
        </div>
      </div>
    </div>

  let mapControlsButtons = isDm
  ? <div className="map-controls-remaining-control map-controls-buttons">
      <div
        className="marker-button-create popup-button pop-up-yes"
        onClick={((e) => props.createNewMarkerClick(e))}
      >
        New Marker
      </div>
      <div
        className="map-button-create popup-button pop-up-yes"
        onClick={((e) => props.requestNewMap(e))}
      >
        New Map
      </div>
    </div>
  : <div className="map-controls-remaining-control map-controls-buttons"></div>;

  return(
    <div className="map-controls">
      <div className="map-marker-selection-section">
        <div className="map-marker-header-wrapper">
          <div className="control-header">{markerText}</div>
          <div className="marker-controls">
            {mapMarkers}
          </div>
        </div>
      </div>
      <div className="remaining-map-controls">
        {colorSection}
        <div className="map-controls-remaining-control">
          <div className="map-marker-header-wrapper">
            <div className="control-header">Select Map Indicator</div>
            <div
              className="marker-indicator"
              style={dynamicIndicatorStyling}
              onClick={((e) => props.selectMapIndicator(e))}
            >
              <i className="fa-solid fa-location-pin"></i>
            </div>
          </div>
        </div>
        {mapControlsButtons}
      </div>
    </div>
  )
}

export default CampaignMapControls
